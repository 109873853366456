<template>
  <div class="block-hero" :class="data.variant">
    <div class="block-hero__grid">
      <div class="block-hero__content" :class="data.variant">
        <AppComponentRenderer
          v-if="data.content && data.content.length > 0"
          :data="data.content"
        />
        <AtomHeading v-if="computedValues.title" :text="computedValues.title" appereance="h1" />
        <AtomLead v-if="computedValues.lead" :text="computedValues.lead" />
      </div>
      <div class="block-hero__image-container" :class="data.variant">
        <AtomImage
          v-if="
            data.image && data.image.length > 0 && data.image[0].component === 'AtomImage' || computedValues.heroImage
          "
          :is-strapi="computedValues.heroImage ? true : false"
          :data="computedValues.heroImage ? computedValues.heroImage : data.image[0]?.image"
          class="block-hero__image"
          :disable-lazy="true"
        />
        <BlockSliderHero
          v-else-if="data.image && data.image.length === 1"
          :data="data.image[0]"
          class="block-hero__slider"
        />
      </div>
      <AtomImage
        v-if="
          data.logo && data.logo.length === 1 || computedValues.logo
        "
        :is-strapi="computedValues.logo ? true : false"
        :data="computedValues.logo ? computedValues.logo : data.logo[0]?.image"
        class="block-hero__brand-logo"
      />
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
    data: {
        type: Object,
        default: () => {},
    },
});

/*
    Computed Data
*/

const storyStore = useStoryStore();

const computedValues = computed(() => {
    let heroImage = null;
    let title = null;
    let lead = null;
    let logo = null;

    if (props.data?.strapi_data_key_image) {
        heroImage = storyStore.getConnectedStrapiValue(props.data.strapi_data_key_image);
    }

    if (props.data?.strapi_data_key_title) {
        title = storyStore.getConnectedStrapiValue(props.data.strapi_data_key_title);
    }

    if (props.data?.strapi_data_key_lead) {
        lead = storyStore.getConnectedStrapiValue(props.data.strapi_data_key_lead);
    }

    if (props.data?.strapi_data_key_logo) {
        logo = storyStore.getConnectedStrapiValue(props.data.strapi_data_key_logo);
    }

    return {
        heroImage,
        title,
        lead,
        logo,
    };
});

</script>

<style lang="scss">
.block-hero {
    @include fluid('margin-bottom', 20px, 40px);

    height: 690px;
    background-color: $C_GREEN_BRIGHT;
    border-bottom-right-radius: 280px;
    isolation: isolate;

    .block-hero__grid {
        @include grid-layout();
        position: relative;
        width: 100%;
        max-width: var(--grid-width);
        height: 100%;
        padding: 0 var(--page-padding);
        margin: 0 auto;
    }

    .block-hero__brand-logo {
        @include grid-columns(1);
        @include fluid('width', 103px, 227px);
        @include fluid('bottom', 20px, 80px);

        position: absolute;
        display: flex;
        overflow: hidden;
        align-items: center;
        justify-content: center;
        border-radius: 12px;
        aspect-ratio: 1.31;
        background-color: $C_WHITE;

        img {
            position: absolute;
            top: 0;
            left: 20%;
            width: 60%;
            height: 100%;
            object-fit: contain;
            object-position: center center;
        }
    }

    .block-hero__content {
        @include fluid('padding-top', 0px, 100px);
        @include fluid('padding-right', 0px, 50px);
        display: flex;
        flex-direction: column;
        justify-content: center;

        .atom-heading {
            margin-bottom: 16px;

            @include mobile {
                margin-bottom: 23px;
            }
        }

        &.small {
            @include grid-columns(1, 9);

            @include tablet {
                @include grid-columns(1, 6);
            }
        }

        &.medium {
            @include grid-columns(1, 8);

            @include tablet {
                @include grid-columns(1, 6);
            }
        }

        &.large {
            display: none;
        }

        @include mobile {
            margin-bottom: 53px;
        }
    }

    .block-hero__image-container {
        position: relative;
        display: flex;
        overflow: hidden;
        height: 100%;
        align-items: center;
        justify-content: center;
        margin-right: calc(0px - var(--grid-offset));
        background-color: $C_GREEN__HERO;
        border-bottom-right-radius: 280px;
        border-top-left-radius: 120px;
        isolation: isolate;

        &.small {
            @include grid-columns(11, 8);

            @include tablet {
                @include grid-columns(7, 6);
            }
            @include mobile {
                @include grid-columns(2, 4);
            }
        }

        &.medium {
            @include grid-columns(9, 10);

            @include tablet {
                @include grid-columns(7, 6);
            }

            @include mobile {
                @include grid-columns(2, 4);
            }
        }

        &.large {
            @include grid-full();
            padding: 0;
            border-top-left-radius: 0;
        }

        &:has(.block-hero__slider) {
            padding: 0;
        }

        .block-hero__image {
            z-index: 1;
            width: 100%;
            height: 100%;
            margin: 0;

            .atom-image {
                height: 100%;
                margin: 0;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &:before {
            position: absolute;
            right: -140px;
            bottom: -230px;
            width: 563px;
            height: 618px;
            background-color: $C_PRIMARY;
            content: '';
            filter: blur(165px);
            opacity: 0.75;
            transform: matrix(-0.87, -0.49, 0.5, -0.87, 0, 0);

            @include mobile {
                display: none;
            }
        }

        &:after {
            position: absolute;
            right: 244px;
            bottom: 72px;
            width: 143px;
            height: 143px;
            background-color: $C_GREEN_DARK;
            content: '';
            filter: blur(114.5px);
            transform: matrix(-0.87, -0.49, 0.5, -0.87, 0, 0);

            @include mobile {
                display: none;
            }
        }

        @include mobile {
            margin-left: calc(0px - var(--grid-offset));
            border-bottom-right-radius: 60px;
            border-top-left-radius: 60px;
        }
    }

    @include mobile {
        padding-top: 140px;
        border-bottom-right-radius: 80px;

        &.large {
            height: 400px;
            padding-top: 0;
        }
    }
}
</style>
